.services-wrapper .section {
  margin-top: 32px;
  margin-bottom: 32px;
}

.flex-column { 
  max-width : 260px;
}

.container {
  /* background: #f9f9f9; */
}

img {
  margin: 5px;
}