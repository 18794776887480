.navContainer {
  background-color: #fff;
  border-bottom: 0.1px #5A5A5A solid;
}

.navHeader {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.navHeader .navMsg {
  line-height: 40px;
  text-align: center;
  font-size: 24px;
  text-decoration: none;
  color: #000;
} 

.navHeader .navContact {
  letter-spacing: 1.5px;
  font-size: 24px;
  font-weight: 700;
  color: #256EBD;

  margin: 0;
}

.nav {
  background-color: white;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  font-size: 1.2rem;
}

.nav-links {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 4rem;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0.25rem;
}

.nav li {
  border-bottom: 3px solid transparent;
  height: fit-content;
}

.nav li:hover, .nav li.active {
  border-bottom: 3px solid #256EBD;
}

.nav-logo {
  height: 80px;
  margin: 10px;
}

.mobile-menu-icon {
  display: none;
}

@media screen and (max-width: 780px) {
  .navHeader {
    justify-content: space-between;
    padding: 0 16px;
  }

  .navHeader .navMsg {
    display: none;
  }

  .nav-logo {
    height: 50px;
    display: flex;
    top: 0;
    left: 0;
  }

  .nav-links {
    display: none;
  }

  .nav-links-mobile {
    position: absolute;
    display: block;
    list-style: none;
    left: 0;
    top: 70px;
    transition: all 0.5s ease-out;
    width: 100%;
    
    background-color: white;
    padding: 0;
    border-bottom: 0.1px #5A5A5A solid;
  }

  .home, .aboutus, .services, .contactus {
    color: white;
    text-align: center;
    padding: 32px;
    width: 100%;
    transition: all 0.5s ease;
  }

  .mobile-menu-icon {
    display: block;
    position: absolute;
    font-size: 1.2em;
    color: #256EBD;
    background-color: white;
    border: none;
    outline: none;
    top: 20px;
    right: 25px;
  }

  .nav a {
    justify-content: center;
    
  }

  .nav li:hover {
    border-bottom: none;
    background-color: #5A5A5A;
    color: white;
  }
  .nav li.active {
    border-bottom: none;
    background-color: #256EBD;
    color: white;
  }
}


@media screen and (min-width: 781px) {

  .nav-links-mobile {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 4rem;
  }
}
