.home-bg-img {
  background-image: url("../assets/temp-bg.jpg");
  height: 100%;
  min-height: 550px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home-bg-text {
  position: relative;
}

.home-title {
  margin: 0;
  position: absolute;
  bottom: 10px;
  font-size: 24px;
  font-weight: 300;
  padding: 45px 20px;
  background: rgba(255, 255, 255, 0.6);
}

.home-content .middle-text {
  font-size: 32px;
  text-align: center;
  padding: 16px;
  /* color: #256EBD; */
}

.home-content .section {
  padding: 15px 30px;
}

/* .home-content .section .subtitle {
  margin-bottom: 16px;
} */

.home-content .section .serviceLink {
  text-decoration: none;
  color: #000;
  cursor: pointer;
}

.home-content .section .serviceLink:hover {
  color: #000;
  opacity: 50%;
  transition: .3s ease-in-out;
}

.home-content .section .serviceImage {
  max-height: 280px;
  object-fit: cover;
  margin: 0;
}

.home-content .youtube {
  padding-top: 10px;
  padding-bottom: 10px;
}

.home-content .youtube a{
  text-decoration: none;
  color: #000;
}

.home-content .youtube-name {
  display: inline;
  vertical-align: middle;
}

@media screen and (max-width: 780px) {
  .home-bg-img {
    min-height: 350px;
  }

  .home-title {
    font-size: 18px;
    padding: 20px;
    text-align: center;
  }
}