.footer-wrapper {
  padding: 20px 40px;
  background-color: #256EBD;
  color: #fff;
}

.contact-method {
  font-size: 20px;
  padding: 16px;
}

.contact-method div {
  display: inline-block;
  margin: 0 8px;
}

.contact-method a {
  text-decoration: none;
  color: #fff;
}

.emergency-text {
  color: red;

}

.float-end a {
  color: #000;
}

.copyright {
  display: inline-flex;
}

@media screen and (max-width: 780px) {
  .copyright {
    display: block;
    text-align: center;
  }
  .float-end {
    float: none !important;
    text-align: center;
  }
}